import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CardBatchStatusEnum } from '~/api/constants';
import { useCardsServiceCardsControllerListBatches } from '~/api/queries';
import { CardBatch, CardsService } from '~/api/requests';
import { useOnReachEnd } from '~/hooks/use-reach-end';
import { setError } from '~/redux/reducers/application';
import { ErrorsEnum } from '~/translates/error/types';

interface ICardsHook {
  cards?: CardBatch[];
  isLoading: boolean;
  onReachEndLoading: boolean;
}

const CARDS_PER_PAGE = 15;

export const useCardsListHook = (): ICardsHook => {
  const dispatch = useDispatch();

  const [cards, setCards] = useState<CardBatch[]>([]);
  const [enabled, setEnabled] = useState(true);
  const [onReachEndLoading, setOnReachEndLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const getCards = useCardsServiceCardsControllerListBatches(
    {
      page,
      perPage: CARDS_PER_PAGE,
    },
    ['listCards', page],
    { enabled }
  );

  useOnReachEnd(() => {
    if (!onReachEndLoading && !getCards.isPending && cards.length > 0 && enabled) {
      setOnReachEndLoading(true);
      setPage((prev) => prev + 1);
    }
  });

  useEffect(() => {
    const updateBatches = async () => {
      if (getCards.data) {
        const batches = await updateBatchesStatus(getCards.data.data);

        setCards((prev) => {
          const filteredPrev = prev.filter((existing) => !batches.some((batch) => batch.id === existing.id));
          return page === 1 ? batches : [...filteredPrev, ...batches];
        });

        setOnReachEndLoading(false);
        if (batches.length < CARDS_PER_PAGE) setEnabled(false);
      }
    };

    updateBatches();
  }, [getCards.data, page]);

  const updateBatchesStatus = async (batches: CardBatch[]): Promise<CardBatch[]> => {
    setIsLoading(true);

    try {
      type CardBatchStatusEnumType = (typeof CardBatchStatusEnum)[keyof typeof CardBatchStatusEnum];

      const newBatches = await Promise.all(
        batches.map(async (card) => {
          if (card.status === CardBatchStatusEnum.PENDING || card.status === CardBatchStatusEnum.PROCESSING) {
            const response = await CardsService.cardsControllerGetBatchCardStatus({ externalId: card.id });
            return { ...card, status: response.status as CardBatchStatusEnumType };
          }

          return card;
        })
      );

      return newBatches;
    } catch (_) {
      dispatch(setError(ErrorsEnum.BATCH_STATUS_ERROR));
      return batches;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    cards,
    isLoading: (isLoading || getCards.isPending || getCards.isFetching) && page === 1 && enabled,
    onReachEndLoading,
  };
};
