import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ProfileEnum, RoleEnum, TemplateEmailEnum } from '~/api/constants';
import {
  useUserServiceUserControllerCreate,
  useUserServiceUserControllerDelete,
  useUserServiceUserControllerGetAllUsers,
  useUserServiceUserControllerResendEmail,
} from '~/api/queries';
import { CreateUserRequestDto, UserResponseDto } from '~/api/requests';
import { useOnReachEnd } from '~/hooks/use-reach-end';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { setSuccess } from '~/redux/reducers/application';
import { SuccessEnum } from '~/translates/success/types';
import { unflatten } from '~/utils/unformat-value';

import { userSchema } from './permissions.schema';

interface UserError {
  name?: string;
  document?: string;
  email?: string;
}

interface IPermissionsHook {
  user: CreateUserRequestDto;
  users?: UserResponseDto[];
  errors: UserError;
  isCreateModeUser: boolean;
  onDeleteUserLoading: boolean;
  wasDeleted: boolean;
  isButtonDisabled: boolean;
  isLoading: boolean;
  onReachEndLoading: boolean;
  onCreateUserLoading: boolean;
  isManager: boolean;
  handleData: (name: string, value: string) => void;
  onCreateUser: () => void;
  onRemoveUserAccess: (id: string) => Promise<void>;
  onResendEmail: (email: string) => Promise<void>;
  toggleAddUser: () => void;
}

const USERS_PER_PAGE = 15;

const INITIAL_USER_STATE: CreateUserRequestDto = {
  name: '',
  document: '',
  email: '',
  role: RoleEnum.ADMIN,
  profile: ProfileEnum.CLIENT_CONSULTANT,
};

const INITIAL_USER_ERROR_STATE: UserError = {
  name: '',
  document: '',
  email: '',
};

const INITIAL_STATE = {
  user: INITIAL_USER_STATE,
  errors: INITIAL_USER_ERROR_STATE,
  isEnabled: true,
  isCreateModeUser: false,
  onReachEndLoading: false,
  page: 1,
};

export const usePermissionsHook = (): IPermissionsHook => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [state, setState] = useState(INITIAL_STATE);
  const [allUsers, setAllUsers] = useState<UserResponseDto[]>([]);

  const { isProfile } = useUserScopes();

  const resetToInitialState = () => {
    setAllUsers([]);
    setState(INITIAL_STATE);
    refetchUsers();
    queryClient.invalidateQueries({ queryKey: ['getAllUsers'] });
  };

  const {
    data: users,
    isPending,
    isFetching,
    isRefetching,
    refetch: refetchUsers,
  } = useUserServiceUserControllerGetAllUsers(
    {
      page: state.page,
      perPage: USERS_PER_PAGE,
    },
    ['getAllUsers', state.page],
    { enabled: state.isEnabled, staleTime: 0 }
  );

  const onCreateUserQuery = useUserServiceUserControllerCreate({
    onSuccess: () => {
      resetToInitialState();
      dispatch(setSuccess(SuccessEnum.ACCESS_CREATED));
    },
  });

  const onDeleteUserQuery = useUserServiceUserControllerDelete({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAllUsers'] });
      dispatch(setSuccess(SuccessEnum.ACCESS_REMOVED));
    },
  });

  const onResendEmailQuery = useUserServiceUserControllerResendEmail({
    onSuccess: () => dispatch(setSuccess(SuccessEnum.RESEND_INVITE)),
  });

  useEffect(() => {
    if (users) {
      setAllUsers((prev) => {
        const filteredPrev = prev.filter(
          (existing) => !users.data.some((newUser) => newUser.id === existing.id)
        );
        return state.page === 1 ? users.data : [...filteredPrev, ...users.data];
      });

      setState((prev) => ({
        ...prev,
        isEnabled: users.data.length >= USERS_PER_PAGE,
        onReachEndLoading: false,
      }));
    }
  }, [users, state.page, onDeleteUserQuery.isSuccess, onCreateUserQuery.isSuccess]);

  useEffect(() => {
    const validation = userSchema.safeParse(state.user);
    if (!validation.success) {
      const { email, document, name } = validation.error.flatten().fieldErrors;
      setState({
        ...state,
        errors: {
          name: state.user.name ? name?.[0] : '',
          email: state.user.email && email?.[0],
          document: state.user.document && document?.[0],
        },
      });
    } else {
      setState({ ...state, errors: INITIAL_USER_ERROR_STATE });
    }
  }, [state.user]);

  useOnReachEnd(() => {
    const loading = isFetching || isPending || isRefetching;

    if (!state.onReachEndLoading && !loading && allUsers.length > 0 && state.isEnabled) {
      setState((prev) => ({ ...prev, onReachEndLoading: true, page: prev.page + 1 }));
    }
  });

  const handleData = (name: string, value: string) => {
    setState((prev) => ({ ...prev, user: { ...prev.user, [name]: value } }));
  };

  const onCreateUser = async () => {
    const { document, email, name, profile } = state.user;

    onCreateUserQuery.mutate({
      requestBody: {
        name,
        email,
        document: unflatten(document),
        role: RoleEnum.ADMIN,
        profile,
      },
    });
  };

  const onRemoveUserAccess = async (id: string) => {
    onDeleteUserQuery.mutate({ id }, { onSuccess: resetToInitialState });
  };

  const onResendEmail = async (email: string) => {
    onResendEmailQuery.mutate({
      requestBody: { email, templateEmail: TemplateEmailEnum.FIRST_ACCESS },
    });
  };

  const toggleAddUser = () => {
    setState({
      ...state,
      isCreateModeUser: !state.isCreateModeUser,
      user: INITIAL_USER_STATE,
      errors: INITIAL_USER_ERROR_STATE,
    });
  };

  const isButtonDisabled = () => {
    return Object.values(state.errors).some((val) => val) || Object.values(state.user).some((val) => !val);
  };

  return {
    isCreateModeUser: state.isCreateModeUser,
    user: state.user,
    users: allUsers,
    errors: state.errors,
    isLoading: (isFetching || isPending || isRefetching) && state.page === 1 && state.isEnabled,
    onDeleteUserLoading: onDeleteUserQuery.isPending,
    wasDeleted: onDeleteUserQuery.isSuccess,
    onCreateUserLoading: onCreateUserQuery.isPending,
    onReachEndLoading: state.onReachEndLoading,
    isButtonDisabled: isButtonDisabled(),
    isManager: isProfile(ProfileEnum.MANAGER),
    handleData,
    toggleAddUser,
    onRemoveUserAccess,
    onResendEmail,
    onCreateUser,
  };
};
