import { IconographyNames } from '@vizir-banking/design-system';
import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Divider as BaseDivider,
  Link as BaseLink,
  LinkVariants,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor, getOpacityColor } from '~/styles/theme/get-theme';

import { PaymentOptions } from './types';

const getByType = (type: PaymentOptions, first: string, second: string) => {
  return type === PaymentOptions.TED || type === PaymentOptions.CREDITS ? first : second;
};

const getBorderColor = (type: PaymentOptions, isDisabled: boolean, isActive: boolean) => {
  if (isDisabled) return getColor('neutral', 'lighter');
  if (type === PaymentOptions.CREDITS) {
    return isActive ? getColor('primary', 'dark') : getColor('neutral', 'dark');
  }
  return 'initial';
};

export const SummaryContainer = styled.div`
  width: 100%;
  padding: 16px 32px 24px;
  border: 1px solid ${getColor('neutral', 'light')};
  border-radius: 8px;
  margin: 32px 0 24px;
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
`;

export const Divider = styled(BaseDivider)`
  flex: 1;
`;

export const Title = styled(Typography).attrs({
  variant: TypographyVariants.SUBTITLE,
})`
  font-weight: 700;
  color: ${getColor('neutral', 'darkest')};
  margin: 8px 0;
`;

export const Label = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_S,
})`
  color: ${getColor('neutral', 'darker')};
  margin-bottom: 24px;
`;

export const Dialog = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'darkest')};
  font-weight: 700;
`;

export const Value = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'darkest')};
  text-align: left;
  width: 100%;
`;

export const ValueBold = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? getColor('primary', 'regular') : getColor('neutral', 'darkest'))};
  font-weight: 700;
  text-align: left;
  width: 100%;
`;

export const OptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const OptionWrapper = styled.div<{ type: PaymentOptions; isDisabled: boolean; isActive: boolean }>`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
  padding: 16px 24px;
  border: 2px solid ${({ type, isDisabled, isActive }) => getBorderColor(type, isDisabled, isActive)};
  border-radius: 8px;
  cursor: ${({ type }) => getByType(type, 'pointer', 'not-allowed')};
  opacity: ${({ type }) => getByType(type, '1', '0.5')};
`;

export const OptionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const OptionTitle = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})<{ isDisabled?: boolean }>`
  color: ${({ isDisabled }) => (isDisabled ? getColor('neutral', 'medium') : getColor('neutral', 'darkest'))};
`;

export const OptionHint = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})<{ isDisabled?: boolean }>`
  color: ${({ isDisabled }) => (isDisabled ? getColor('neutral', 'light') : getColor('success', 'medium'))};
  font-weight: 700;
`;

export const CurrentBalance = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_S,
})<{ isBold?: boolean; isOuter?: boolean }>`
  display: inline;
  color: ${getColor('success', 'darkest')};
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  background-color: ${({ isOuter }) => (isOuter ? getOpacityColor('#A1D8C940', 0.25) : 'transparent')};
  padding: 2px 4px;
  border-radius: 500px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
`;

export const Link = styled(BaseLink).attrs({
  linkVariant: LinkVariants.s,
})`
  color: ${getColor('error', 'medium')};
  text-decoration: none;
`;

export const PrimaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
  withTrailingIcon: true,
  trailingIconName: IconographyNames.chevronRight,
  trailingIconColor: getColor('neutral', 'darkest'),
}))``;

export const TotalValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  gap: 4px;
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.custom,
})``;

export const SkeletonFill = styled.div`
  width: 175px;
  height: 24px;
  border-radius: 8px;
`;
