import { Checkbox } from '@vizir-banking/design-system/web';
import { formatToBRL } from 'brazilian-values';
import { isEqual, isNil, noop } from 'lodash';
import { ReactElement } from 'react';

import { OrderDetails } from '~/api/requests';
import { OrderSummary } from '~/components/order-summary/order-summary';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';
import { autoSingleStyle } from '~/utils/auto-single-style';

import {
  ButtonWrapper,
  Column,
  CurrentBalance,
  Label,
  OptionContainer,
  OptionHint,
  OptionTitle,
  OptionTitleWrapper,
  OptionWrapper,
  PrimaryButton,
  Skeleton,
  SkeletonFill,
  SummaryContainer,
  Title,
  TotalValueWrapper,
  Value,
  ValueBold,
} from './payment-type.styles';
import { PaymentOptions, PaymentSelectedOptions } from './types';

interface IPaymentType {
  paymentTypes: PaymentSelectedOptions;
  fileData: OrderDetails;
  handlePaymentTypeChange: (value: PaymentOptions) => void;
  onContinue: () => void;
  totalValue: number;
  creditBalance: number;
  isBalanceLoading: boolean;
  isCreditsDisabled: boolean;
}

export const RechargePaymentTypeLayout = ({
  fileData,
  paymentTypes,
  handlePaymentTypeChange,
  onContinue,
  creditBalance,
  isBalanceLoading,
  isCreditsDisabled,
  totalValue,
}: IPaymentType): ReactElement => {
  const translate = useTranslation('pages.rh.recharge.newRecharge.tabs.paymentType');

  const renderOrderSummary = () => {
    return (
      <SummaryContainer>
        <Column>
          <Title>{translate('summary.title')}</Title>
          {<OrderSummary isLoading={false} data={fileData as unknown as OrderDetails} />}
        </Column>
      </SummaryContainer>
    );
  };

  const isDisabled = (type: PaymentOptions | 'continue') => {
    if (type === PaymentOptions.CREDITS) {
      return isCreditsDisabled;
    }
    if (type === 'continue') {
      return (
        isEqual(paymentTypes, { ted: false, pix: false, billet: false, credits: false }) ||
        isNil(paymentTypes) ||
        (isEqual(paymentTypes, { ted: false, pix: false, billet: false, credits: true }) &&
          creditBalance < totalValue)
      );
    }
    return type !== PaymentOptions.TED;
  };

  const renderPaymentOption = (type: PaymentOptions) => {
    return (
      <OptionWrapper
        onClick={() => {
          if (isDisabled(type)) return;
          handlePaymentTypeChange(type);
        }}
        isDisabled={isDisabled(type)}
        isActive={paymentTypes[type]}
        type={type}
      >
        {/** Manter ternário caso, agora ou futuramente, passemos a utilizar RadioButton ao invés de Checkbox */}
        {type === PaymentOptions.CREDITS ? (
          <Checkbox
            onClick={() => {
              if (isDisabled(type)) return;
              handlePaymentTypeChange(type);
            }}
            isSelected={paymentTypes[type]}
            isDisabled={isDisabled(type)}
            containerProps={{
              onClick: noop,
            }}
          />
        ) : (
          <Checkbox
            onClick={() => {
              if (isDisabled(type)) return;
              handlePaymentTypeChange(type);
            }}
            isSelected={paymentTypes[type]}
            isDisabled={isDisabled(type)}
            containerProps={{
              onClick: noop,
            }}
          />
        )}
        <Column
          style={{
            gap: 8,
          }}
        >
          {type === PaymentOptions.CREDITS ? (
            <OptionTitleWrapper>
              <OptionTitle isDisabled={isDisabled(type)}>{translate(`options.${type}.title`)}</OptionTitle>

              {isBalanceLoading ? (
                <Skeleton>
                  <SkeletonFill />
                </Skeleton>
              ) : (
                <CurrentBalance isOuter>
                  {autoSingleStyle(
                    translate(`options.${type}.currentBalance`, {
                      balance: formatToBRL(creditBalance),
                    }),
                    '*',
                    (props) => (
                      <CurrentBalance {...props} />
                    ),
                    (props) => (
                      <CurrentBalance {...props} isBold={true} />
                    )
                  )}
                </CurrentBalance>
              )}
            </OptionTitleWrapper>
          ) : (
            <OptionTitle isDisabled={isDisabled(type)}>{translate(`options.${type}.title`)}</OptionTitle>
          )}

          {type === PaymentOptions.CREDITS && (
            <OptionHint isDisabled={isDisabled(type)}>{translate(`options.${type}.hint`)}</OptionHint>
          )}
        </Column>
      </OptionWrapper>
    );
  };

  const renderPaymentOptions = () => {
    return (
      <OptionContainer>
        <Title>{translate('options.title')}</Title>
        <Label>{translate('options.subtitle')}</Label>
        {renderPaymentOption(PaymentOptions.TED)}
        {/* {renderPaymentOption(PaymentOptions.PIX)}
        {renderPaymentOption(PaymentOptions.BILLET)} */}
        {renderPaymentOption(PaymentOptions.CREDITS)}
      </OptionContainer>
    );
  };

  const renderTotalValueSummary = () => {
    const isTed = paymentTypes.ted;
    const isCredits = paymentTypes.credits;
    const isAny = isTed || isCredits;
    const isBoth = isTed && isCredits;
    const finalValue = isBoth ? totalValue - creditBalance : totalValue;

    const renderBothOptions = () => {
      return (
        <Value>
          {translate('totalValue.bothOptionsBalance', {
            value: formatToBRL(totalValue),
            discount: formatToBRL(creditBalance),
          })}
        </Value>
      );
    };
    const renderCreditsOnly = () => {
      return (
        <Value>
          {creditBalance > finalValue
            ? translate('totalValue.finalBalance', {
                finalBalance: formatToBRL(creditBalance - finalValue),
              })
            : translate('totalValue.noBalance')}
        </Value>
      );
    };
    const getTranslation = () => {
      if (isTed && isCredits) return 'both';
      if (isTed && !isCredits) return 'ted';
      if (!isTed && isCredits) return 'credits';
    };

    return (
      <TotalValueWrapper>
        <Value>
          {translate('totalValue.base')} {isAny && translate(`totalValue.${getTranslation()}`)}
        </Value>
        {isBoth && renderBothOptions()}
        {!isTed && isCredits && renderCreditsOnly()}
        <ValueBold isActive={isAny}>{formatToBRL(finalValue)}</ValueBold>
      </TotalValueWrapper>
    );
  };

  const renderButtons = () => {
    return (
      <ButtonWrapper>
        {renderTotalValueSummary()}

        {/* <TertiaryButton
          label={translate('buttons.goBack')}
          onClick={() => {
            onChangeNewRechargeStep(NewRechargeSteps.VALIDATION);
          }}
        /> */}
        <PrimaryButton
          label={translate('buttons.next')}
          isDisabled={isDisabled('continue')}
          onClick={onContinue}
        />
      </ButtonWrapper>
    );
  };

  return (
    <FragmentCol style={{ flex: 1 }}>
      {renderOrderSummary()}
      {renderPaymentOptions()}
      {renderButtons()}
    </FragmentCol>
  );
};
