import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SplitModeEnum } from '~/api/constants';
import {
  useEmployerServiceEmployerControllerGetEmployerAccountBalance,
  useLoadOrderServiceOrderControllerCreateOrder,
} from '~/api/queries';
import { OrderDetails, SplitModeEnum as SplitModeEnumType } from '~/api/requests';
import { clearDetails, clearOrder, selectOrder, selectOrderDetails } from '~/redux/reducers/new-recharge';

import { RechargeSteps } from '../types';
import { DistributionOptions } from './distribution/types';
import { PaymentOptions, PaymentSelectedOptions } from './payment-type/types';
import { NewRechargeSteps } from './types';

interface INewRecharge {
  currentStep: NewRechargeSteps;
  onChangeStep: (step: NewRechargeSteps) => void;
  file: File | null;
  onChangeFile: (file: File) => void;
  fileData: OrderDetails;
  onChangeFileData: (data: OrderDetails) => void;
  stepHasError: boolean;
  onSetStepHasError: (hasError: boolean) => void;
  paymentTypes: PaymentSelectedOptions;
  onChangePaymentTypes: (value: PaymentOptions) => void;
  totalValue: number;
  creditBalance: number;
  isBalanceLoading: boolean;
  tedValue: number;
  onChangeTedValue: (value: number) => void;
  handleCreateOrder: () => Promise<void>;
  isOrderLoading: boolean;
  creationSuccess: boolean;
  distributionType: DistributionOptions | null;
  onChangeDistributionType: (type: DistributionOptions) => void;
  selectedDate: Date | null;
  onChangeSelectedDate: (date: Date | null) => void;
}

interface IUseNewRecharge {
  onChangeRechargeStep: (step: RechargeSteps) => void;
}

export const useNewRecharge = ({ onChangeRechargeStep }: IUseNewRecharge): INewRecharge => {
  const dispatch = useDispatch();

  const details = useSelector(selectOrderDetails);
  const order = useSelector(selectOrder);
  const totalValue = details?.totalBalance ?? 0;

  const INITIAL_STATE = {
    ted: false,
    pix: false,
    billet: false,
    credits: false,
  };

  const [currentStep, setCurrentStep] = useState<NewRechargeSteps>(NewRechargeSteps.PAYMENT_METHOD);
  const [file, setFile] = useState<File | null>(null);
  const [stepHasError, setStepHasError] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState<PaymentSelectedOptions>(INITIAL_STATE);
  const [tedValue, setTedValue] = useState(0);
  const [distributionType, setDistributionType] = useState<DistributionOptions | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const getSplitMode = (): SplitModeEnumType => {
    if (
      distributionType === DistributionOptions.INSTANT_WEEKDAY ||
      distributionType === DistributionOptions.INSTANT_WEEKEND
    ) {
      return SplitModeEnum.INSTANTANEOUS;
    }
    if (distributionType === DistributionOptions.SCHEDULE) {
      return SplitModeEnum.SCHEDULED;
    }
    return SplitModeEnum.MANUAL;
  };

  const getScheduledDate = (): string | undefined => {
    if (distributionType === DistributionOptions.SCHEDULE && selectedDate) {
      return selectedDate.toISOString();
    }
    return undefined;
  };

  const onChangeStep = (step: NewRechargeSteps) => {
    if (step === NewRechargeSteps.PAYMENT && tedValue === 0) {
      onChangeRechargeStep(RechargeSteps.BALANCE);
      return;
    }

    setCurrentStep(step);
  };

  const onChangeFile = (file: File) => {
    setFile(file);
  };

  const onChangePaymentTypes = (value: PaymentOptions) => {
    if (creditBalance >= totalValue) {
      if (value === PaymentOptions.CREDITS) {
        setPaymentTypes({
          credits: true,
          pix: false,
          billet: false,
          ted: false,
        });
      }
      if (value === PaymentOptions.TED) {
        setPaymentTypes({
          credits: false,
          pix: false,
          billet: false,
          ted: true,
        });
      }
    } else {
      setPaymentTypes((prevState) => ({
        ...prevState,
        [value]: !prevState[value],
      }));
    }
  };

  const onChangeDistributionType = (type: DistributionOptions) => {
    setDistributionType(type);
  };

  const onChangeSelectedDate = (date: Date | null) => {
    setSelectedDate(date);
  };

  const getCreditBalance = useEmployerServiceEmployerControllerGetEmployerAccountBalance();

  const creditBalance = getCreditBalance.data?.balance ?? 0;

  const createOrder = useLoadOrderServiceOrderControllerCreateOrder({
    onSuccess: () => {
      dispatch(clearDetails());
      dispatch(clearOrder());

      onChangeStep(NewRechargeSteps.PAYMENT);
    },
  });

  const handleCreateOrder = async (): Promise<void> => {
    createOrder.mutate({
      requestBody: {
        json: order,
      },
      splitMode: getSplitMode(),
      splitScheduleDate: getScheduledDate(),
    });
  };

  return {
    file,
    onChangeFile,
    fileData: details ?? {
      totalEmployees: 0,
      totalBalance: 0,
      totalItems: 0,
      balances: [],
    },
    onChangeFileData: (_) => {},
    currentStep,
    stepHasError,
    onChangeStep,
    onSetStepHasError: setStepHasError,
    paymentTypes,
    onChangePaymentTypes,
    totalValue,
    creditBalance,
    isBalanceLoading: getCreditBalance.isPending,
    tedValue,
    onChangeTedValue: setTedValue,
    handleCreateOrder,
    isOrderLoading: createOrder.isPending,
    creationSuccess: createOrder.isSuccess,
    distributionType,
    onChangeDistributionType,
    selectedDate,
    onChangeSelectedDate,
  };
};
