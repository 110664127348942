import { useSelector } from 'react-redux';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { usePortalHook } from '~/hooks/use-portal';
import { CreatePasswordPage } from '~/pages/common/create-password/create-password';
import { ForgotPasswordPage } from '~/pages/common/forgot-password/forgot-password';
import { LoginPage } from '~/pages/common/login/login';
import { CompanyList } from '~/pages/issuer-portal/company/companies/companies-list/companies-list';
import { CompanyDetails } from '~/pages/issuer-portal/company/companies/company-details/company-details';
import { NewCompany } from '~/pages/issuer-portal/company/new-company/new-company';
import { HomePage as IssuerHomePage } from '~/pages/issuer-portal/home/home';
import { Permissions as IssuerPermissions } from '~/pages/issuer-portal/permissions/permissions';
import { AddCredit } from '~/pages/rh-portal/add-credit/add-credit';
import { CardsPage } from '~/pages/rh-portal/cards/cards';
import { BatchDetails } from '~/pages/rh-portal/collaborators/batch-details/batch-details';
import { ListBatches } from '~/pages/rh-portal/collaborators/list-batches/list-batches';
import { EmployeeList } from '~/pages/rh-portal/employees/employee-list/employee-list';
import { EmployeeProfile } from '~/pages/rh-portal/employees/employee-profile/profile';
import { EnterpriseAddressPage } from '~/pages/rh-portal/enterprise-address/enterprise-address';
import { HomePage as RhHomePage } from '~/pages/rh-portal/home/home';
import { Permissions as RhPermissions } from '~/pages/rh-portal/permissions/permissions';
import { RechargePage } from '~/pages/rh-portal/recharge/recharge';
import { selectToken } from '~/redux/reducers/user';

import { ProtectedRoute } from './protected-route';
import { COMMON_SCREENS_DEFINITIONS } from './screen-definitions/common';
import { ISSUER_SCREENS_DEFINITIONS } from './screen-definitions/issuer-portal';
import { RH_SCREENS_DEFINITIONS } from './screen-definitions/rh-portal';

interface RequireAuth {
  children: React.ReactNode;
}

const RequireAuth = () => {
  const token = useSelector(selectToken);
  const { isRh } = usePortalHook();

  if (token) {
    const route = isRh ? RH_SCREENS_DEFINITIONS.rhHome : ISSUER_SCREENS_DEFINITIONS.issuerHome;
    return <Navigate to={route} replace />;
  }

  return <Navigate to={COMMON_SCREENS_DEFINITIONS.login} replace />;
};

export const routes = createBrowserRouter([
  {
    path: RH_SCREENS_DEFINITIONS.root,
    element: <RequireAuth />,
  },
  {
    path: COMMON_SCREENS_DEFINITIONS.login,
    element: <LoginPage />,
  },
  {
    path: COMMON_SCREENS_DEFINITIONS.createPassword,
    element: <CreatePasswordPage />,
  },
  {
    path: COMMON_SCREENS_DEFINITIONS.forgotPassword,
    element: <ForgotPasswordPage />,
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: RH_SCREENS_DEFINITIONS.rhHome,
        element: <RhHomePage />,
      },
      {
        path: RH_SCREENS_DEFINITIONS.listEmployee,
        element: <EmployeeList />,
      },
      {
        path: RH_SCREENS_DEFINITIONS.profileEmployee,
        element: <EmployeeProfile />,
      },
      {
        path: RH_SCREENS_DEFINITIONS.listBatches,
        element: <ListBatches />,
      },
      {
        path: RH_SCREENS_DEFINITIONS.detailsBatch,
        element: <BatchDetails />,
      },
      {
        path: RH_SCREENS_DEFINITIONS.recharge,
        element: <RechargePage />,
      },
      {
        path: RH_SCREENS_DEFINITIONS.rhPermissions,
        element: <RhPermissions />,
      },
      {
        path: RH_SCREENS_DEFINITIONS.cards,
        element: <CardsPage />,
      },
      {
        path: RH_SCREENS_DEFINITIONS.address,
        element: <EnterpriseAddressPage />,
      },
      {
        path: RH_SCREENS_DEFINITIONS.addCredit,
        element: <AddCredit />,
      },
      { path: ISSUER_SCREENS_DEFINITIONS.issuerHome, element: <IssuerHomePage /> },
      { path: ISSUER_SCREENS_DEFINITIONS.new, element: <NewCompany /> },
      { path: ISSUER_SCREENS_DEFINITIONS.list, element: <CompanyList /> },
      { path: ISSUER_SCREENS_DEFINITIONS.details, element: <CompanyDetails /> },
      { path: ISSUER_SCREENS_DEFINITIONS.issuerPermissions, element: <IssuerPermissions /> },
    ],
  },
  {
    path: '*',
    element: <div>Ops, rota não encontrada</div>,
  },
]);
