import { ErrorsEnum } from './types';

export const ptBrErrorTranslations = {
  errors: {
    generic_error: {
      title: 'Ops, algo deu errado!',
      description: 'Tivemos um problema! Por favor, tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.AUTHENTICATION_FAILED]: {
      title: 'Ops, algo deu errado!',
      description: 'E-mail ou senha não correspondem.',
      button: 'Entendi',
    },
    [ErrorsEnum.ACCESS_CODE_NOT_EXISTS]: {
      title: 'Código incorreto',
      description: 'Verifique o código e tente novamente.',
      button: 'Entendi',
    },
    [ErrorsEnum.EXPIRED_ACCESS_CODE]: {
      title: 'Ops, algo deu errado!',
      description: 'Que pena, seu link expirou.',
      button: 'Fechar',
    },
    [ErrorsEnum.INVALID_PASSWORD_POLICY]: {
      title: 'A senha não atende aos requisitos de segurança',
      description: 'Verifique as regras e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.PASSWORD_UPDATE_FAIL]: {
      title: 'Falha ao atualizar a senha',
      description: 'Houve um erro ao tentar atualizar sua senha. Tente novamente mais tarde.',
      button: 'Entendi',
    },
    [ErrorsEnum.ACCOUNT_ALREADY_EXISTS]: {
      title: 'Conta já existente',
      description: 'Já existe uma conta associada a este email. Tente recuperar a senha ou use outro email.',
      button: 'Entendi',
    },
    [ErrorsEnum.TOKEN_WITHOUT_SCOPES]: {
      title: 'Ops, algo deu errado!',
      description: 'Puxa, você não tem permissão para fazer isso.',
      button: 'Entendi',
    },
    [ErrorsEnum.UNAUTHORIZED_USER]: {
      title: 'Usuário não autorizado',
      description: 'Você não tem permissão para realizar essa ação. Entre em contato com o administrador.',
      button: 'Entendi',
    },
    [ErrorsEnum.EXPIRED_TOKEN]: {
      title: 'Sessão Expirada',
      description: 'Sua sessão expirou. Clique no botão abaixo para fazer login novamente.',
      button: 'Entendi',
    },
    [ErrorsEnum.ZIPCODE_NOT_FOUND]: {
      title: 'CEP não encontrado',
      description: 'Não conseguimos localizar o CEP fornecido. Verifique se o CEP está correto.',
      button: 'Fechar',
    },
    [ErrorsEnum.MUST_CONTAIN_EIGHT_DIGITS]: {
      title: 'O valor inserido deve ter exatamente oito dígitos',
      description: 'Verifique e tente novamente.',
      button: 'Entendi',
    },
    [ErrorsEnum.INCONSISTENT_ZIPCODE_LIB]: {
      title: 'Houve um problema ao verificar o CEP',
      description: 'Tente novamente mais tarde.',
      button: 'Fechar',
    },
    [ErrorsEnum.EMAIL_NOT_SENT]: {
      title: 'Não foi possivel enviar o e-mail',
      description: 'Tente novamente mais tarde.',
      button: 'Fechar',
    },
    [ErrorsEnum.DOCK_ONE_ERROR]: {
      title: 'Ops, algo deu errado!',
      description: 'Ocorreu um problema ao processar sua solicitação. Tente novamente mais tarde.',
      button: 'Fechar',
    },
    [ErrorsEnum.DOCK_ONE_BAD_REQUEST]: {
      title: 'Ops, algo deu errado!',
      description:
        'A solicitação enviada contém dados incorretos. Verifique as informações e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.DOCK_ONE_NOT_FOUND]: {
      title: 'Ops, algo deu errado!',
      description: 'Verifique os dados fornecidos.',
      button: 'Fechar',
    },
    [ErrorsEnum.DOCK_ONE_UNPROCESSABLE_ENTITY]: {
      title: 'Ops, algo deu errado!',
      description: 'Os dados fornecidos não puderam ser processados. Revise e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.TENANT_ID_IS_REQUIRED]: {
      title: 'Ops, algo deu errado!',
      description: 'Tivemos um problema! Por favor, tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.DELETE_USER_IN_FIREBASE]: {
      title: 'Ops, algo deu errado!',
      description: 'Não foi possível remover o usuário. Tente novamente mais tarde.',
      button: 'Fechar',
    },
    [ErrorsEnum.CANNOT_ASSOCIATE_PRIMARY_TENANT]: {
      title: 'Ops, algo deu errado!',
      description:
        'Não foi possível concluir a associação do recurso principal. Verifique as permissões e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.VALIDATION_INVALID]: {
      title: 'Ops, algo deu errado!',
      description: 'Código de verificação incorreto.',
      button: 'Fechar',
    },
    [ErrorsEnum.DOCUMENT_IS_REQUIRED]: {
      title: 'Documento obrigatório',
      description: 'É necessário fornecer um documento válido para prosseguir.',
      button: 'Fechar',
    },
    [ErrorsEnum.VALIDATION_REQUIRED]: {
      title: 'Validação obrigatória',
      description: 'Este campo é obrigatório. Por favor, preencha-o antes de continuar.',
      button: 'Fechar',
    },
    [ErrorsEnum.USER_EMAIL_UNIQUE]: {
      title: 'Ops, algo deu errado!',
      description: 'O email fornecido já está em uso. Use um email diferente.',
      button: 'Fechar',
    },
    [ErrorsEnum.USER_DOCUMENT_NUMBER_UNIQUE]: {
      title: 'Ops, algo deu errado!',
      description: 'O documento fornecido já está em uso. Use um documento diferente.',
      button: 'Fechar',
    },
    [ErrorsEnum.USER_NOT_FOUND]: {
      title: 'Usuário não encontrado',
      description: 'Não encontramos um usuário com os dados fornecidos. Verifique e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.CANNOT_DELETE_THE_USER]: {
      title: 'Não é possível excluir o usuário',
      description: 'Este usuário não pode ser excluído no momento. Tente novamente mais tarde.',
      button: 'Fechar',
    },
    [ErrorsEnum.UNCREATED_CODE]: {
      title: 'Código não criado',
      description: 'O código solicitado não foi criado. Verifique a solicitação e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.UNKNOWN_DATABASE_ERROR]: {
      title: 'Erro desconhecido',
      description: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
      button: 'Fechar',
    },
    [ErrorsEnum.UNAUTHORIZED_ACTION]: {
      title: 'Ação não autorizada',
      description: 'Você não tem permissão para realizar essa ação. Entre em contato com o administrador.',
      button: 'Fechar',
    },
    [ErrorsEnum.UNAUTHORIZED_ASSOCIATION]: {
      title: 'Associação não autorizada',
      description:
        'Você não tem permissão para associar este funcionário. Entre em contato com o administrador.',
      button: 'Fechar',
    },
    [ErrorsEnum.UNAUTHORIZED_DISSOCIATION]: {
      title: 'Dissociação não autorizada',
      description:
        'Você não tem permissão para dissociar este funcionário. Entre em contato com o administrador.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_NO_FILE]: {
      title: 'Nenhum arquivo foi fornecido para a proposta em lote',
      description: 'Anexe um arquivo válido e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_EMPTY_FILE]: {
      title: 'Arquivo da proposta em lote vazio',
      description:
        'O arquivo fornecido para a proposta em lote está vazio. Forneça um arquivo com dados válidos.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_INVALID_FILE]: {
      title: 'Arquivo da proposta em lote inválido',
      description:
        "O arquivo fornecido para a proposta em lote é inválido. Verifique o formato correto em 'planilha modelo' e tente novamente.",
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_EMPTY_COLUMNS]: {
      title: 'Colunas vazias na proposta em lote',
      description:
        'O arquivo da proposta em lote contém colunas vazias. Preencha as colunas adequadamento ou exclua as colunas e tente novamente',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_INVALID_COLUMNS]: {
      title: 'Colunas inválidas na proposta em lote',
      description:
        "O arquivo da proposta em lote contém colunas inválidas. Verifique o formato em 'planilha modelo' e tente novamente.",
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_MAX_BATCH_SIZE]: {
      title: 'Tamanho máximo de lote excedido',
      description: 'O tamanho do lote excede o limite permitido.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_MIN_BATCH_SIZE]: {
      title: 'Tamanho mínimo de lote não atendido',
      description:
        'O tamanho do lote é menor que o mínimo permitido. Adicione um ou mais itens e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_FILE_WITHOUT_ERRORS]: {
      title: 'Arquivo sem erros na proposta em lote',
      description: 'O arquivo fornecido não contém erros.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_NOT_FOUND_AT_DATABASE]: {
      title: 'Proposta em lote não encontrada',
      description: 'A proposta em lote não foi encontrada. Verifique a referência e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_NO_ERRORS_OR_REFUSED]: {
      title: 'Ops, algo deu errado!',
      description: 'Verifique os dados antes de reenviar.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_EMPTY_ROW]: {
      title: 'Linhas vazias na proposta em lote',
      description:
        "A proposta em lote contém linhas vazias. Preencha todos os campos de acordo com a 'planilha modelo' e tente novamente.",
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_NO_FILE]: {
      title: 'Recarga em lote sem arquivo',
      description:
        'Nenhum arquivo foi enviado para a recarga em lote. Anexe um arquivo válido e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_EMPTY_FILE]: {
      title: 'Arquivo vazio na recarga em lote',
      description: 'O arquivo enviado está vazio. Verifique o conteúdo do arquivo e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_INVALID_EXTENSION]: {
      title: 'Formato de arquivo inválida na recarga em lote',
      description: 'Use um formato aceito e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_EMPTY_COLUMNS]: {
      title: 'Colunas vazias na recarga em lote',
      description: 'O arquivo enviado contém colunas vazias. Complete todas as colunas e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_INVALID_COLUMNS]: {
      title: 'Colunas inválidas na recarga em lote',
      description:
        "O arquivo enviado contém colunas inválidas. Verifique o formato correto em 'planilha modelo' e tente novamente.",
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_MIN_ITEMS]: {
      title: 'Número mínimo de itens não atendido',
      description:
        'O número de itens na recarga em lote é inferior ao mínimo permitido. Adicione um ou mais itens e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_MAX_ITEMS]: {
      title: 'Número máximo de itens excedido',
      description:
        'O tamanho do lote excede o limite permitido. O máximo aceito são 10.000 linhas, ajuste e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_CPF_NOT_FOUND]: {
      title: 'CPF não encontrado na recarga em lote',
      description:
        'Não conseguimos encontrar o CPF na recarga em lote. Verifique o número e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_DUPLICATED_CPF]: {
      title: 'CPF duplicado na recarga em lote',
      description: 'O CPF fornecido já existe na recarga em lote. Elimine duplicatas e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.ORDER_EMPTY_ROW]: {
      title: 'Linhas vazias na recarga em lote',
      description: 'A recarga contém linhas vazias. Preencha todos os campos e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.ORDER_INVALID_SPLIT_SCHEDULE_DATE]: {
      title: 'Data de agendamento inválida para distribuição do pedido',
      description:
        'A data de agendamento para a distribuição do pedido é inválida. Verifique e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.ORDER_SPLIT_SCHEDULE_DATE_BEFORE_TODAY]: {
      title: 'Data de agendamento para distribuição anterior a hoje',
      description:
        'A data de agendamento para a distribuição do pedido não pode ser anterior a hoje. Ajuste a data e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.ORDER_SPLIT_SCHEDULE_DATE_INVALID_TED]: {
      title: 'Data de agendamento para distribuição com TED inválida',
      description:
        'A data de agendamento para distribuição com TED é inválida. Verifique os dados e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.EMPLOYEE_NOT_FOUND]: {
      title: 'Funcionário não encontrado',
      description: 'Não encontramos um funcionário com os dados fornecidos. Verifique e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.ADDRESS_LIMIT_REACHED]: {
      title: 'Limite de endereços alcançado',
      description: 'O limite de endereços foi atingido. Não é possível adicionar mais endereços no momento.',
      button: 'Fechar',
    },
    [ErrorsEnum.ADDRESS_NOT_FOUND]: {
      title: 'Endereço não encontrado',
      description: 'O endereço fornecido não foi encontrado. Verifique os dados e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.EMPLOYER_NOT_FOUND]: {
      title: 'Empresa não encontrada',
      description: 'Não encontramos a empresa com os dados fornecidos. Verifique e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.EMPLOYER_DOCUMENT_NOT_FOUND]: {
      title: 'Documento da empresa não encontrado',
      description: 'Não conseguimos localizar o documento da empresa. Verifique os dados e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.EMPLOYER_ACCOUNT_NOT_FOUND]: {
      title: 'Conta da empresa não encontrada',
      description: 'A conta da empresa não foi encontrada. Verifique os dados e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.EMPLOYER_ALREADY_EXISTS]: {
      title: 'Empresa já existente',
      description:
        'Já existe uma empresa registrada com os dados fornecidos. Verifique os dados e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.ORDER_ALREADY_PROCESSED]: {
      title: 'Pedido já processado',
      description: 'Este pedido já foi processado anteriormente. Verifique o status e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.ORDER_NOT_FOUND]: {
      title: 'Pedido não encontrado',
      description:
        'Não conseguimos localizar o pedido com os dados fornecidos. Verifique os dados e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.ORDER_PROCESSING]: {
      title: 'Pedido em processamento',
      description: 'Aguarde e tente novamente mais tarde.',
      button: 'Fechar',
    },
    [ErrorsEnum.MODE_DOES_NOT_ALLOW_SPLIT]: {
      title: 'Ops, algo deu errado!',
      description:
        'O modo de distribuição não permite divisão de pedidos. Verifique o modo e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.STATUS_DOES_NOT_ALLOW_SPLIT]: {
      title: 'Ops, algo deu errado!',
      description: 'O status do pedido não permite distribuição. Verifique o status e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.CARDS_QUANTITY_MAX]: {
      title: 'Quantidade máxima de cartões excedida',
      description: 'O número de cartões excede o limite máximo permitido.',
      button: 'Fechar',
    },
    [ErrorsEnum.CARDS_QUANTITY_MIN]: {
      title: 'Quantidade mínima de cartões não atendida',
      description:
        'O número de cartões é menor que o mínimo permitido. Adicione mais cartões e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.CARDS_EXPIRATION_DATE_TOO_EARLY]: {
      title: 'Data de expiração do cartão não permitida',
      description:
        'A data de expiração do cartão fornecido é muito próxima. Escolha uma data mais distante e tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.INVALID_FILE]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado não é um formato compatível.',
      button: 'Fechar',
    },
    [ErrorsEnum.SPLIT_ORDER]: {
      title: 'Ops, algo deu errado!',
      description: 'Falha ao distribuir o pedido. Por favor, tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.UNPROCESSABLE_ENTITY]: {
      title: 'Ops, algo deu errado!',
      description: 'Dados inválidos.',
      button: 'Fechar',
    },
    [ErrorsEnum.INVALID_EMPLOYER_PHONE]: {
      title: 'Ops, algo deu errado!',
      description: 'Telefone da empresa inválido.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_STATUS_ERROR]: {
      title: 'Ops, algo deu errado!',
      description: 'Não foi possível atualizar o status dos lotes.',
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_INVALID_VALUES]: {
      title: 'Ops, algo deu errado!',
      description: 'A planilha informada contém dados inválidos.',
      button: 'Entendi',
    },
    error_boundary: {
      title: 'Algo deu errado!',
      support: 'Estamos enfrentando dificuldades técnicas.',
      description:
        'Por favor, volte ao login, entre na sua conta novamente e refaça o processo mais uma vez. Se o problema persistir, entre em contato com o suporte.',
      button: {
        understood: 'Entendi',
      },
    },
  },
};
