import { SuccessEnum } from './types';

export const ptBrSuccessTranslations = {
  success: {
    generic_success: {
      title: 'Deu tudo certo!',
      description: 'Sua solicitação foi concluída com sucesso.',
      button: 'Entendi',
    },
    [SuccessEnum.EMAIL_SENT]: {
      title: 'E-mail enviado com sucesso!',
      description: 'Verifique sua caixa de entrada.',
      button: 'Entendi',
    },
    [SuccessEnum.PASSWORD_CREATED]: {
      title: 'Senha criada com sucesso!',
      description: 'Agora você já pode acessar o {{REACT_APP_CUSTOMER_NAME}}, é só fazer o login.',
    },
    [SuccessEnum.ACCESS_REMOVED]: {
      title: 'Acesso removido com sucesso!',
      description: 'O acesso foi removido.',
      button: 'Entendi',
    },
    [SuccessEnum.ACCESS_CREATED]: {
      title: 'Usuário cadastrado com sucesso!',
      description: 'O e-mail com o convite para acesso a plataforma foi enviado.',
      button: 'Entendi',
    },
    [SuccessEnum.RESEND_INVITE]: {
      title: 'E-mail reenviado com sucesso!',
      description: 'O e-mail com o convite para acesso a plataforma foi reenviado.',
      button: 'Entendi',
    },
    [SuccessEnum.BATCH_PROPOSAL_UPLOADED]: {
      title: 'Planilha enviada com sucesso!',
      description: 'Agora é só aguardar o processamento.',
      button: 'Entendi',
    },
    [SuccessEnum.COLLABORATOR_UPDATED]: {
      title: 'Sucesso!',
      description: 'Dados do colaborador atualizados com sucesso',
      button: 'Fechar',
    },
    [SuccessEnum.CARD_REQUEST]: {
      title: 'Sucesso!',
      description: 'Solicitação enviada com sucesso!',
      button: 'Ok',
    },
    [SuccessEnum.UPDATED_ENTERPRISE_ADDRESS]: {
      title: 'Sucesso!',
      description: 'Endereço atualizado com sucesso!',
      button: 'Ok',
    },
    [SuccessEnum.CREATED_ENTERPRISE_ADDRESS]: {
      title: 'Sucesso!',
      description: 'Endereço adicionado com sucesso!',
      button: 'Ok',
    },
    [SuccessEnum.DELETED_ENTERPRISE_ADDRESS]: {
      title: 'Sucesso!',
      description: 'Endereço excluído com sucesso!',
      button: 'Ok',
    },
    [SuccessEnum.SPLIT_ORDER]: {
      title: 'Sucesso!',
      description: 'Pedido distribuído com sucesso! Agora é só aguardar o processamento.',
      button: 'Ok',
    },
    [SuccessEnum.CREATE_COMPANY]: {
      title: 'Empresa criada com sucesso!',
      description: 'Agora você pode acessar a empresa.',
      button: 'Ok',
    },
    [SuccessEnum.COMPANY_UPDATED]: {
      title: 'Empresa atualizada com sucesso!',
      description: 'Os dados da empresa foram atualizados com sucesso.',
      button: 'Ok',
    },
  },
};
