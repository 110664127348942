import { OrderDetails } from '~/api/requests';
import { StepByStep, StepsType } from '~/components/step-by-step/step-by-step';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { RechargeSteps } from '../types';
import { RechargeDistribution } from './distribution/distribution';
import { DistributionOptions } from './distribution/types';
import { PageHeader } from './new-recharge.styles';
import { RechargePayment } from './payment/payment';
import { RechargePaymentType } from './payment-type/payment-type';
import { PaymentOptions, PaymentSelectedOptions } from './payment-type/types';
import { NewRechargeSteps } from './types';

interface INewRecharge {
  currentStep: NewRechargeSteps;
  onChangeStep: (step: NewRechargeSteps) => void;
  onChangeRechargeStep: (step: RechargeSteps) => void;
  file: File | null;
  onChangeFile: (file: File) => void;
  stepHasError: boolean;
  onSetStepHasError: (hasError: boolean) => void;
  fileData: OrderDetails;
  onChangeFileData: (data: OrderDetails) => void;
  paymentTypes: PaymentSelectedOptions;
  onChangePaymentTypes: (value: PaymentOptions) => void;
  totalValue: number;
  creditBalance: number;
  isBalanceLoading: boolean;
  tedValue: number;
  onChangeTedValue: (value: number) => void;
  handleCreateOrder: () => Promise<void>;
  isOrderLoading: boolean;
  creationSuccess: boolean;
  distributionType: DistributionOptions | null;
  onChangeDistributionType: (type: DistributionOptions) => void;
  selectedDate: Date | null;
  onChangeSelectedDate: (date: Date | null) => void;
}

export const NewRechargeLayout = ({
  currentStep,
  onChangeStep,
  onChangeRechargeStep,
  stepHasError,
  fileData,
  paymentTypes,
  onChangePaymentTypes,
  totalValue,
  creditBalance,
  isBalanceLoading,
  tedValue,
  onChangeTedValue,
  handleCreateOrder,
  distributionType,
  onChangeDistributionType,
  selectedDate,
  onChangeSelectedDate,
  creationSuccess,
  isOrderLoading,
}: INewRecharge) => {
  const translate = useTranslation('pages.rh.recharge.newRecharge');

  const goBack = () => {
    if (currentStep === NewRechargeSteps.DISTRIBUTION_METHOD) {
      onChangeStep(NewRechargeSteps.PAYMENT_METHOD);
      return;
    }

    onChangeRechargeStep(RechargeSteps.BALANCE);
  };

  const renderPaymentMethod = () => {
    return (
      <RechargePaymentType
        paymentTypes={paymentTypes}
        onChangePaymentTypes={onChangePaymentTypes}
        onChangeNewRechargeStep={onChangeStep}
        fileData={fileData}
        totalValue={totalValue}
        creditBalance={creditBalance}
        isBalanceLoading={isBalanceLoading}
        onChangeTedValue={onChangeTedValue}
      />
    );
  };

  const renderDistributionMethod = () => {
    return (
      <RechargeDistribution
        fileData={fileData}
        paymentTypes={paymentTypes}
        distributionType={distributionType}
        selectedDate={selectedDate}
        onChangeDistributionType={onChangeDistributionType}
        onChangeSelectedDate={onChangeSelectedDate}
        totalValue={totalValue}
        creditBalance={creditBalance}
        onChangeNewRechargeStep={onChangeStep}
        handleCreateOrder={handleCreateOrder}
        isOrderLoading={isOrderLoading}
        creationSuccess={creationSuccess}
      />
    );
  };

  const renderPayment = () => {
    return <RechargePayment onChangeRechargeStep={onChangeRechargeStep} totalValue={tedValue} />;
  };

  const steps: StepsType[] = [
    {
      label: translate('stepByStep.step0'),
      renderContent: <></>,
    },
    {
      label: translate('stepByStep.step2'),
      renderContent: renderPaymentMethod(),
    },
    {
      label: translate('stepByStep.step3'),
      renderContent: renderDistributionMethod(),
    },
    {
      label: translate('stepByStep.step4'),
      renderContent: renderPayment(),
    },
  ];

  const getStepNumber = (step: NewRechargeSteps) => {
    const steps = {
      [NewRechargeSteps.PAYMENT_METHOD]: 1,
      [NewRechargeSteps.DISTRIBUTION_METHOD]: 2,
      [NewRechargeSteps.PAYMENT]: 3,
    };

    return steps[step];
  };

  return (
    <FragmentCol style={{ flex: 1 }}>
      <PageHeader leadingGoBack={goBack} title={translate('pageHeader.subtitle')} />
      <StepByStep steps={steps} currentStep={getStepNumber(currentStep)} hasError={stepHasError} />
    </FragmentCol>
  );
};
