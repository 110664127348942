export const ptBR = {
  uploadStep: {
    title: 'Enviar arquivo',
    manifestTitle: 'Dados que esperamos:',
    manifestDescription: '',
    maxRecordsExceeded: (maxRecords: string) => `Muitos registros. Até ${maxRecords} permitidos`,
    dropzone: {
      title: 'Envie um arquivo .xlsx, .xls, .csv ou .txt',
      errorToastDescription: 'upload rejeitado',
      activeDropzoneTitle: 'Solte o arquivo aqui...',
      buttonTitle: 'Selecionar arquivo',
      loadingTitle: 'Processando...',
    },
    selectSheet: {
      title: 'Selecione a planilha a ser usada',
      nextButtonTitle: 'Próximo',
      backButtonTitle: 'Voltar',
    },
  },
  selectHeaderStep: {
    title: 'Selecionar cabeçalho',
    nextButtonTitle: 'Próximo',
    backButtonTitle: 'Voltar',
  },
  matchColumnsStep: {
    title: 'Correspondência de colunas',
    nextButtonTitle: 'Próximo',
    backButtonTitle: 'Voltar',
    userTableTitle: 'Sua tabela',
    templateTitle: 'Se tornará',
    selectPlaceholder: 'Selecionar coluna...',
    ignoredColumnText: 'Coluna ignorada',
    subSelectPlaceholder: 'Selecionar...',
    matchDropdownTitle: 'Corresponder',
    unmatched: 'Não correspondida',
    duplicateColumnWarningTitle: 'Outra coluna não selecionada',
    duplicateColumnWarningDescription: 'As colunas não podem ser duplicadas',
  },
  validationStep: {
    title: 'Validar dados',
    nextButtonTitle: 'Confirmar',
    backButtonTitle: 'Voltar',
    noRowsMessage: 'Nenhum dado encontrado',
    noRowsMessageWhenFiltered: 'Nenhum dado contendo erros',
    discardButtonTitle: 'Descartar linhas selecionadas',
    filterSwitchTitle: 'Mostrar apenas linhas com erros',
  },
  alerts: {
    confirmClose: {
      headerTitle: 'Sair do fluxo de importação',
      bodyText: 'Você tem certeza? Suas informações atuais não serão salvas.',
      cancelButtonTitle: 'Cancelar',
      exitButtonTitle: 'Sair do fluxo',
    },
    submitIncomplete: {
      headerTitle: 'Erros detectados',
      bodyText: 'Ainda existem algumas linhas que contêm erros. Linhas com erros serão ignoradas ao enviar.',
      bodyTextSubmitForbidden: 'Ainda existem algumas linhas contendo erros.',
      cancelButtonTitle: 'Entendi',
      finishButtonTitle: 'Enviar',
    },
    submitError: {
      title: 'Erro',
      defaultMessage: 'Ocorreu um erro ao enviar os dados',
    },
    unmatchedRequiredFields: {
      headerTitle: 'Nem todas as colunas foram correspondidas',
      bodyText: 'Existem colunas obrigatórias que não foram correspondidas ou ignoradas.',
      listTitle: 'Colunas não correspondidas:',
      cancelButtonTitle: 'Entendi',
      continueButtonTitle: 'Continuar',
    },
    toast: {
      error: 'Erro',
    },
  },
};

export const enUs = {};
