export const RH_SCREENS_DEFINITIONS = {
  root: '/',
  rhHome: '/home',
  listEmployee: '/employee/list',
  profileEmployee: '/employee/profile',
  listBatches: '/batch/list',
  detailsBatch: '/batch/details',
  recharge: '/order/list',
  rhPermissions: '/user/list',
  cards: '/cards',
  address: '/address',
  addCredit: '/add-credit',
};
