import { OrderDetails } from '~/api/requests';

import { NewRechargeSteps } from '../types';
import { useRechargePaymentType } from './payment-type.hook';
import { RechargePaymentTypeLayout } from './payment-type.layout';
import { PaymentOptions, PaymentSelectedOptions } from './types';

interface IPaymentType {
  onChangeNewRechargeStep: (step: NewRechargeSteps) => void;
  fileData: OrderDetails;
  paymentTypes: PaymentSelectedOptions;
  onChangePaymentTypes: (value: PaymentOptions) => void;
  totalValue: number;
  creditBalance: number;
  isBalanceLoading: boolean;
  onChangeTedValue: (value: number) => void;
}

export const RechargePaymentType = ({
  onChangeNewRechargeStep,
  fileData,
  onChangePaymentTypes,
  paymentTypes,
  totalValue,
  creditBalance,
  isBalanceLoading,
  onChangeTedValue,
}: IPaymentType) => {
  const properties = useRechargePaymentType({
    onChangeNewRechargeStep,
    onChangePaymentTypes,
    paymentTypes,
    totalValue,
    creditBalance,
    onChangeTedValue,
  });

  return (
    <RechargePaymentTypeLayout {...properties} fileData={fileData} isBalanceLoading={isBalanceLoading} />
  );
};
