import { ReactSpreadsheetImport } from '@rubcube/react-spreadsheet-import';
import { Result } from '@rubcube/react-spreadsheet-import/types/types';
import { ReactElement } from 'react';
import { createGlobalStyle, useTheme } from 'styled-components';

import { ColumnDto } from '~/api/requests/types.gen';
import { Fragment } from '~/styles/global-styles';

import { ptBR } from './translates';

export interface FilePickerModalProps<T extends Record<string, unknown>> {
  isOpen: boolean;
  onClose: () => void;
  fields: ColumnDto[];
  onSubmit: (data: T[]) => Promise<void>;
}

const GlobalStyleOverride = createGlobalStyle<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen &&
    `
    div {
      display: block;
    }
  `}
`;

export const FilePickerModal = <T extends Record<string, unknown>>({
  isOpen,
  onClose,
  fields,
  onSubmit,
}: FilePickerModalProps<T>): ReactElement => {
  const theme = useTheme();

  const handleOnSubmit = async (data: Result<keyof T & string>) => {
    const updatedData = data.validData.map((item) => {
      return {
        ...item,
      } as T;
    });

    await onSubmit(updatedData);
  };

  return (
    <Fragment>
      <GlobalStyleOverride isOpen={isOpen} />
      <ReactSpreadsheetImport<keyof T & string>
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleOnSubmit}
        fields={fields}
        translations={ptBR}
        isNavigationEnabled
        allowInvalidSubmit={false}
        maxRecords={10000}
        customTheme={{
          colors: {
            rsi: {
              50: theme.designSystem.palette.primary.lightest,
              100: theme.designSystem.palette.primary.lightest,
              200: theme.designSystem.palette.primary.light,
              300: theme.designSystem.palette.primary.light,
              400: theme.designSystem.palette.primary.regular,
              500: theme.designSystem.palette.primary.regular,
              600: theme.designSystem.palette.primary.dark,
              700: theme.designSystem.palette.primary.dark,
              800: theme.designSystem.palette.primary.darkest,
              900: theme.designSystem.palette.primary.darkest,
            },
          },
          components: {
            Button: {
              baseStyle: {
                borderRadius: 36,
                padding: 6,
              },
            },
            UploadStep: {
              baseStyle: {
                tableWrapper: {
                  h: '98px',
                },
              },
            },
          },
        }}
      />
    </Fragment>
  );
};
