import { Order } from '~/api/requests';

import { RechargeSteps } from '../types';
import { useBalanceRecharge } from './balance-recharge.hook';
import { BalanceRechargeLayout } from './balance-recharge.layout';

interface IBalanceRecharge {
  onSelectOrder?: (order: Order) => void;
  onChangeStep: (step: RechargeSteps) => void;
  onToggleReleaseOrderModal?: (id?: string) => void;
}

export const BalanceRechargePage = ({
  onChangeStep,
  onSelectOrder,
  onToggleReleaseOrderModal,
}: IBalanceRecharge) => {
  const props = useBalanceRecharge({ onChangeStep });

  return (
    <BalanceRechargeLayout
      {...props}
      onChangeStep={onChangeStep}
      onSelectOrder={onSelectOrder}
      onToggleReleaseOrderModal={onToggleReleaseOrderModal}
    />
  );
};
