import { useQueryClient } from '@tanstack/react-query';
import { formatToCEP, formatToPhone } from 'brazilian-values';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useAuthServiceAuthControllerLoginBySwithRole,
  useCommonServiceCommonControllerGetAddressByZipCode,
  useEmployerServiceEmployerControllerGetEmployee,
  useEmployerServiceEmployerControllerUpdateEmployee,
} from '~/api/queries';
import { AddressDto, ApiError, EmailDto, OpenAPI, PhoneDto, SimplifiedEmployers } from '~/api/requests';
import { ISSUER_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/issuer-portal';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { clearSuccess, setError, setSuccess } from '~/redux/reducers/application';
import { setIsRh, setIsSwitchingPortals } from '~/redux/reducers/portal';
import { setIssuerToken, setToken } from '~/redux/reducers/user';
import { SuccessEnum } from '~/translates/success/types';
import { useTranslation } from '~/translates/use-translate';
import { formatPhone } from '~/utils/format-phone';
import { unflatten } from '~/utils/unformat-value';

import onEmployerValidation from './company-details.schema';
import { BoxType, ButtonType, ICompanyDetails, IContactFields, IErrorFields } from './company-details.types';

const INITIAL_ADDRESS_STATE: AddressDto = {
  id: '',
  city: '',
  complement: '',
  country: '',
  neighborhood: '',
  number: '',
  state: '',
  street: '',
  zipCode: '',
};

export const useCompanyDetailsHook = (): ICompanyDetails => {
  const queryClient = useQueryClient();
  const translate = useTranslation('pages.issuer.company.details');
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();

  const [isContactEditMode, setIsContactEditMode] = useState(false);
  const [isAddressEditMode, setIsAddressEditMode] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const [contact, setContact] = useState<IContactFields>();
  const [address, setAddress] = useState<AddressDto>(INITIAL_ADDRESS_STATE);
  const [errors, setErrors] = useState<IErrorFields>({});

  const goBack = () => navigation(-1);

  const company: SimplifiedEmployers = location?.state?.company ?? undefined;

  const {
    data: employer,
    isPending: isEmployerPending,
    isRefetching: isEmployerRefetching,
  } = useEmployerServiceEmployerControllerGetEmployee(
    {
      id: company?.personId,
    },
    ['employerDetails', company],
    { enabled: !isEmpty(company), staleTime: 0 }
  );

  const onGetCommomAddress = useCommonServiceCommonControllerGetAddressByZipCode(
    {
      zipCode: unflatten(address.zipCode).length === 8 ? unflatten(address.zipCode) : '',
    },
    undefined,
    { enabled: unflatten(address.zipCode).length === 8 && isEnabled }
  );

  const onUpdateCompany = useEmployerServiceEmployerControllerUpdateEmployee({
    onSuccess: () => {
      queryClient.invalidateQueries();
      dispatch(setSuccess(SuccessEnum.COMPANY_UPDATED));
      setIsEnabled(true);
    },
    onError: (err: ApiError) => {
      dispatch(setError(err.body.code));
    },
  });

  const onSwitchRole = useAuthServiceAuthControllerLoginBySwithRole({
    onSuccess: async (session) => {
      dispatch(setIsSwitchingPortals(true));

      await Promise.all([
        dispatch(setIssuerToken()),
        dispatch(setIsRh(true)),
        dispatch(setToken(session.token)),
      ]);

      OpenAPI.HEADERS = {
        ...OpenAPI.HEADERS,
        Authorization: `Bearer ${session.token}`,
      };

      navigation(RH_SCREENS_DEFINITIONS.rhHome);
    },
  });

  const closeModalProps: ButtonType = {
    label: translate('success.button'),
    onClick: async (): Promise<void> => {
      dispatch(clearSuccess());
      navigation(ISSUER_SCREENS_DEFINITIONS.list);
    },
  };

  useEffect(() => {
    if (isEnabled) {
      if (onGetCommomAddress.data) {
        setAddress((prevAddress) => ({
          ...prevAddress,
          zipCode: address.zipCode,
          country: 'BR',
          city: onGetCommomAddress.data.city || '',
          state: onGetCommomAddress.data.state || '',
          neighborhood: onGetCommomAddress.data.neighborhood || '',
          street: onGetCommomAddress.data.street || '',
          complement: '',
          number: '',
        }));
      } else if (unflatten(address.zipCode).length === 8 && !onGetCommomAddress.data) {
        if (!onGetCommomAddress.isPending) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            zipCode: translate('errors.zipCode'),
          }));
        }
        setAddress({ ...INITIAL_ADDRESS_STATE, zipCode: address.zipCode, id: address.id });
      }
    }
  }, [onGetCommomAddress.data, address.zipCode, isEnabled, onGetCommomAddress.isPending]);

  useEffect(() => {
    if (employer) {
      const { email, phone, address } = employer;

      if (phone && email) {
        setContact({
          email: email.email,
          phoneNumber: formatToPhone(phone.phoneNumber.slice(3)),
        });
      }

      if (address) {
        setAddress(address);
      }

      setIsEnabled(false);
    }
  }, [employer]);

  useEffect(() => {
    const validation = onEmployerValidation.safeParse(contact);

    if (!validation.success) {
      const { email, phoneNumber } = validation.error.flatten().fieldErrors;
      setErrors({
        email: contact?.email && email?.[0],
        phoneNumber: contact?.phoneNumber && phoneNumber?.[0],
      });
    } else {
      setErrors({});
    }
  }, [contact]);

  const handleContactData = (name: string, value?: string) => {
    setContact((prev) => ({
      ...prev,
      [name]: name === 'phoneNumber' && value ? formatToPhone(value) : value,
    }));
  };

  const handleAddressData = (name: string, value?: string) => {
    if (name === 'zipCode') {
      setIsEnabled(true);
      setErrors((prev) => ({ ...prev, zipCode: '' }));
    }
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleData = (box: BoxType, name: string, value?: string) => {
    if (name === 'number') value = value?.replace(/\D/g, '');
    if (name === 'state' || name === 'country') value = value?.replace(/[^a-zA-Z\s\.]/g, '');
    if (name === 'zipCode') value = formatToCEP(value ?? '');

    if (box === 'contact') handleContactData(name, value);
    if (box === 'address') handleAddressData(name, value);
  };

  const onEdit = (box: BoxType) => {
    if (box === 'contact') {
      setIsContactEditMode((prev) => !prev);
    } else if (box === 'address') {
      setIsEnabled(false);
      setIsAddressEditMode((prev) => !prev);
    }
  };

  const isButtonDisabled = (box: BoxType): boolean => {
    if (box === 'contact') {
      const hasErrors = !!errors.email || !!errors.phoneNumber;
      const hasFieldFilled = !!contact?.email && !!contact?.phoneNumber;
      return !hasFieldFilled || hasErrors;
    }

    if (box === 'address') {
      const { zipCode, street, country, neighborhood, number, city, state } = address;
      return [zipCode, street, country, neighborhood, number, city, state].some((field) => !field);
    }

    return false;
  };

  const onUpdateEmployer = async (box: BoxType): Promise<void> => {
    const emailRequest: EmailDto | undefined = contact?.email
      ? { id: employer?.email?.id ?? '', email: contact.email }
      : undefined;

    const phoneRequest: PhoneDto | undefined = contact?.phoneNumber
      ? { id: employer?.phone?.id ?? '', phoneNumber: formatPhone(contact.phoneNumber) }
      : undefined;

    const addressRequest: AddressDto | undefined = box === 'address' ? address : undefined;

    onUpdateCompany.mutate({
      id: employer?.personId ?? '',
      requestBody: {
        email: box === 'contact' ? emailRequest : undefined,
        phone: box === 'contact' ? phoneRequest : undefined,
        address: addressRequest,
      },
    });
  };

  const onAccessPortal = async (company: SimplifiedEmployers) => {
    await onSwitchRole.mutateAsync({
      requestBody: {
        company: company.personId,
      },
    });
  };

  return {
    company: employer,
    isLoading: isEmployerPending || isEmployerRefetching,
    isUpdateLoading: onUpdateCompany.isPending,
    isGetAddressInfoLoading:
      onGetCommomAddress.isPending && unflatten(address.zipCode).length === 8 && isEnabled,
    isContactEditMode,
    isAddressEditMode,
    contact,
    errors,
    address,
    closeModalProps,
    isButtonDisabled,
    onEdit,
    handleData,
    onSubmit: onUpdateEmployer,
    onAccessPortal,
    goBack,
  };
};
