import { SuccessEnum } from './types';

export const enUsSuccessTranslations = {
  success: {
    generic_success: {
      title: 'Everything went well!',
      description: 'Your request was successfully completed.',
      button: 'Got it',
    },
    [SuccessEnum.EMAIL_SENT]: {
      title: 'Email sent successfully!',
      description: 'Check your inbox.',
      button: 'Got it',
    },
    [SuccessEnum.PASSWORD_CREATED]: {
      title: 'Password created successfully!',
      description: 'Now you can access {{REACT_APP_CUSTOMER_NAME}}, just log in.',
    },
    [SuccessEnum.ACCESS_REMOVED]: {
      title: 'Access removed successfully!',
      description: 'The access was removed.',
      button: 'Got it',
    },
    [SuccessEnum.ACCESS_CREATED]: {
      title: 'User successfully registered!',
      description: 'The invitation email for platform access has been sent.',
      button: 'Got it',
    },
    [SuccessEnum.RESEND_INVITE]: {
      title: 'Email successfully resent!',
      description: 'The invitation email for platform access has been resent.',
      button: 'Got it',
    },
    [SuccessEnum.BATCH_PROPOSAL_UPLOADED]: {
      title: 'Spreadsheet Uploaded Successfully!',
      description: 'Now just wait for the processing.',
      button: 'Got it',
    },
    [SuccessEnum.COLLABORATOR_UPDATED]: {
      title: 'Success!',
      description: 'Employee data updated successfully!',
      button: 'Close',
    },
    [SuccessEnum.CARD_REQUEST]: {
      title: 'Success!',
      description: 'Request successfully submitted!',
      button: 'Okay',
    },
    [SuccessEnum.UPDATED_ENTERPRISE_ADDRESS]: {
      title: 'Success!',
      description: 'Address updated successfully!',
      button: 'Ok',
    },
    [SuccessEnum.CREATED_ENTERPRISE_ADDRESS]: {
      title: 'Success!',
      description: 'Address added successfully!',
      button: 'Ok',
    },
    [SuccessEnum.DELETED_ENTERPRISE_ADDRESS]: {
      title: 'Success!',
      description: 'Address deleted successfully!',
      button: 'Ok',
    },
    [SuccessEnum.SPLIT_ORDER]: {
      title: 'Success!',
      description: 'Order split successfully! Now just wait for the processing.',
      button: 'Ok',
    },
    [SuccessEnum.CREATE_COMPANY]: {
      title: 'Success!',
      description: 'Company created successfully!',
      button: 'Okay',
    },
    [SuccessEnum.COMPANY_UPDATED]: {
      title: 'Company updated successfully!',
      description: 'Company data updated successfully!',
      button: 'Okay',
    },
  },
};
