import { z } from 'zod';

const emailSchema = z.optional(z.string().email({ message: 'Email inválido' }));
const phoneSchema = z.optional(
  z.string().regex(/^\(\d{2}\)\s\d \d{4}-\d{4}$|^\(\d{2}\)\s\d{4}-\d{4}$/, { message: 'Telefone inválido' })
);

const onEmployerValidation = z.object({
  email: emailSchema,
  phoneNumber: phoneSchema,
});

export default onEmployerValidation;
