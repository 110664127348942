import {
  Dialog as BaseDialog,
  DialogVariants,
  FeedbackType,
  ModalSizes,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

export const Dialog = styled(BaseDialog).attrs({
  size: ModalSizes.small,
  variant: DialogVariants.feedback,
  feedbackType: FeedbackType.error,
})`
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
`;

export const Description = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_S,
})``;
