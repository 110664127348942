import { isCPF } from 'brazilian-values';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ScopeEnum } from '~/api/constants';
import {
  useEmployeeServiceEmployeeControllerGetEmployeesSheetViaEmail,
  useEmployeeServiceEmployeeControllerListEmployees,
} from '~/api/queries';
import { OpenAPI, SimplifiedEmployees } from '~/api/requests';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { useOnReachEnd } from '~/hooks/use-reach-end';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { selectToken } from '~/redux/reducers/user';
import { useTranslation } from '~/translates/use-translate';
import { unflatten } from '~/utils/unformat-value';

interface IEmailState {
  isSendEmailModalVisible?: boolean;
  isSendEmailLoading?: boolean;
  isSuccessModalVisible?: boolean;
  isSendEmailHookEnabled?: boolean;
  isSendEmailEnabled?: boolean;
}

interface IEmployeeListHook {
  employees?: SimplifiedEmployees[];
  document: string;
  error: string;
  emailState: IEmailState;
  isLoading: boolean;
  onReachEndLoading: boolean;
  shouldViewEmployeeProfile: boolean;
  onSelectEmployee: (collaborator: SimplifiedEmployees) => void;
  onChangeDocument: (value: string) => void;
  onSendEmail: () => void;
  onChangeSendEmailHookState: (state: IEmailState) => void;
}

export const EMPLOYEES_PER_PAGE = 20;

const INITIAL_STATE = {
  isSendEmailModalVisible: false,
  isSendEmailLoading: false,
  isSuccessModalVisible: false,
  isSendEmailHookEnabled: false,
  isSendEmailEnabled: false,
};

export const useEmployeeListHook = (): IEmployeeListHook => {
  const navigate = useNavigate();
  const translate = useTranslation('pages.rh.employees.view');
  const token = useSelector(selectToken);
  const { hasScope } = useUserScopes();

  const [data, setData] = useState<SimplifiedEmployees[]>([]);
  const [documentError, setDocumentError] = useState('');
  const [document, setDocument] = useState<string>('');
  const [page, setPage] = useState(1);
  const [isEnabled, setIsEnabled] = useState(true);
  const [onReachEndLoading, setOnReachEndLoading] = useState(false);

  const [emailState, setEmailState] = useState<IEmailState>(INITIAL_STATE);

  const { isSendEmailLoading, isSendEmailHookEnabled, isSendEmailEnabled } = emailState;

  const onChangeSendEmailHookState = (state: IEmailState) => {
    setEmailState((prev) => ({ ...prev, ...state }));
  };

  const getDocument = useCallback(() => {
    return document.length === 14 && !documentError ? unflatten(document) : undefined;
  }, [document]);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem('employeeListScrollPosition');
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem('employeeListScrollPosition');
    }
    onChangeSendEmailHookState(INITIAL_STATE);
  }, []);

  const { data: employees, isPending: isEmployeesPending } =
    useEmployeeServiceEmployeeControllerListEmployees(
      {
        page,
        perPage: EMPLOYEES_PER_PAGE,
        document: getDocument(),
      },
      ['listEmployees', page, document],
      { enabled: isEnabled }
    );

  const onSendEmailQuery = useEmployeeServiceEmployeeControllerGetEmployeesSheetViaEmail(undefined, {
    enabled: isSendEmailHookEnabled,
  });

  useEffect(() => {
    const handleRefetch = async () => {
      await onSendEmailQuery.refetch().then(() => {
        if (onSendEmailQuery.isSuccess) {
          onChangeSendEmailHookState({ isSendEmailModalVisible: false, isSuccessModalVisible: true });
        }
        onChangeSendEmailHookState({ isSendEmailLoading: false });
      });
    };

    if (isSendEmailEnabled && isSendEmailHookEnabled) {
      handleRefetch();
      onChangeSendEmailHookState({ isSendEmailEnabled: false });
    }
  }, [isSendEmailLoading]);

  useEffect(() => {
    if (employees) {
      const newEmployees = employees.data;

      setData((prev) => {
        const filteredPrev = prev.filter(
          (existing) => !newEmployees.some((collaborator) => collaborator.personId === existing.personId)
        );
        return page === 1 ? newEmployees : [...filteredPrev, ...newEmployees];
      });

      if (newEmployees.length === 0 && document.length === 14) setDocumentError(translate('error.notFound'));

      if (newEmployees.length < EMPLOYEES_PER_PAGE) setIsEnabled(false);

      setOnReachEndLoading(false);
    }
  }, [employees?.data, page]);

  useEffect(() => {
    const isValidLength = document.length === 14;
    const isValidDocument = isValidLength && isCPF(document);

    if (document.length === 0) {
      setIsEnabled(true);
      setDocumentError('');
    } else {
      setIsEnabled(isValidDocument);

      if (isValidLength && !isValidDocument) {
        setDocumentError(translate('error.document'));
      } else {
        setDocumentError('');
      }
    }
  }, [document]);

  useEffect(() => {
    OpenAPI.HEADERS = {
      ...OpenAPI.HEADERS,
      Authorization: `Bearer ${token}`,
    };
  }, [token]);

  const onSendEmail = () => {
    onChangeSendEmailHookState({ isSendEmailLoading: true });
    if (!isSendEmailHookEnabled) {
      onChangeSendEmailHookState({
        isSendEmailHookEnabled: true,
        isSendEmailModalVisible: false,
        isSuccessModalVisible: true,
        isSendEmailLoading: false,
      });

      return;
    }
    onChangeSendEmailHookState({ isSendEmailEnabled: true });
  };

  useOnReachEnd(() => {
    if (!onReachEndLoading && !isEmployeesPending && data.length > 0 && isEnabled) {
      setOnReachEndLoading(true);
      setPage((prev) => prev + 1);
    }
  });

  const onSelectEmployee = (employee: SimplifiedEmployees) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(RH_SCREENS_DEFINITIONS.profileEmployee, {
      state: { employee: employee, index: data.findIndex((e) => e.personId === employee.personId) },
    });
  };

  const onChangeDocument = useCallback((document: string) => {
    setDocumentError('');
    setDocument(document);
    const isValidDocument = document.length === 14 && isCPF(document);
    if (isValidDocument) {
      setPage(1);
      setData([]);
    }
  }, []);

  return {
    employees: data,
    document,
    shouldViewEmployeeProfile: hasScope(ScopeEnum.VIEW_PROFILE_EMPLOYEE),
    error: documentError,
    isLoading: isEmployeesPending && page === 1 && isEnabled,
    onReachEndLoading,
    emailState,
    onSelectEmployee,
    onChangeDocument,
    onSendEmail,
    onChangeSendEmailHookState,
  };
};
