/* eslint-disable simple-import-sort/imports */
import {
  Skeleton,
  SkeletonVariants,
  Typography,
  TypographyVariants,
  Icon as BaseIcon,
  Link as BaseLink,
  TextField as BaseTextField,
  Skeleton as BaseSkeleton,
  Button as BaseButton,
  ButtonTypes,
  ButtonSizes,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

import {
  PageHeader as BasePageHeader,
  LeadingContentType,
  TrailingContentType,
} from '~/components/page-header/page-header';
import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenPortalTypes, ScreenTypes } from '~/components/screen/types';
import { IconographyNames } from '@vizir-banking/design-system';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
  portal: ScreenPortalTypes.ISSUER,
})``;

export const Box = styled.div`
  width: 100%;
  margin-top: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: start;
  border: 1px solid ${getColor('neutral', 'medium')};
  border-radius: 8px;
`;

export const BoxWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
`;

export const BoxSkeleton = styled(Skeleton).attrs({
  variant: SkeletonVariants.textField,
})`
  width: 100%;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const BoxTitle = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})``;

export const BoxInfoLabel = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})``;

export const BoxInfoValue = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_M,
})`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BoxInfoWrapper = styled.div`
  width: calc(33.3% - 24px);
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  margin: 32px 24px 0 0;
`;

export const PageHeader = styled(BasePageHeader).attrs({
  leadingContentType: LeadingContentType.GO_BACK,
  trailingContentType: TrailingContentType.BUTTON,
})``;

export const EditIcon = styled(BaseIcon).attrs({
  iconName: IconographyNames.edit,
  color: getColor('neutral', 'darkest'),
})`
  width: 24px;
  height: 24px;
`;

export const Link = styled(BaseLink)``;

export const TextFieldWrapper = styled.div`
  flex-direction: column;
  align-items: start;
`;

export const TextField = styled(BaseTextField).attrs({
  showClearIcon: false,
})`
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  margin: 32px 24px 0 0;
`;

export const TextFieldSkeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.textField,
})`
  width: 240px;
  min-height: 56px;
  margin: 32px 24px 0 0;
`;

export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

export const PrimaryButton = styled(BaseButton).attrs({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
})``;

export const TertiaryButton = styled(BaseButton).attrs({
  buttonType: ButtonTypes.tertiary,
  buttonSize: ButtonSizes.small,
})`
  color: ${getColor('error', 'medium')};
`;
